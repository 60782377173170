<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
</style>
<style lang="scss" scoped>
.card-custom {
  .body {
    .fixed {
      position: fixed;
      width: 77.1rem;
      background-color: #ffffff;
      left: 23.6rem;
      z-index: 1;
      top: 15%;
      .search {
        margin-left: 2% !important;
      }
    }
    .move-below {
      margin-top: 13% !important;
    }
  }
}
</style>
<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="fixed">
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <adv-search
              @search="advSearch"
              :is_company="true"
              :is_date_range="true"
              :is_service="true"
              :is_status="true"
              :is_payment_status="true"
              :reportData="this.excelreports"
              :titleworksheet="this.titleworksheet"
              :json_fields="this.json_fields"
              :header="this.header"
              :is_admin_sp_company="true"
              :report_name="'company_report.xls'"
            ></adv-search>
          </div>
          <div
            style="margin-left: -2rem !important"
            class="col-md-1 col-sm-1"
            v-if="reports.length > 0 && !getLoadingData"
          >
            <!-- <download-excel
              class="btn btn-outline-primary ml-1"
              :data="excelreports"
              :worksheet="search.date_from + ' - ' + search.date_to"
              :header="header"
              :fields="json_fields"
              type="xls"
              name="company-report.xls"
            >
              <i class="test-white fa fa-download"></i>
            </download-excel> -->
          </div>
        </div>
        <!-- <div class="row" v-if="reports.length > 0 && !getLoadingData">
          <div class="col-md-2" v-if="search.company != 'all'">
            <button
              class="btn btn-sm btn-light-success font-weight-bolder font-size-sm"
              @click="createInvoice"
            >
              Generate Invoice
            </button>
          </div>
          <div class="col-md-9"></div>
        </div> -->
      </div>
      <hr v-if="reports.length > 0 && !getLoadingData" />
      <div class="row" v-if="reports.length > 0 && !getLoadingData">
        <div class="col-md-12">
          <table class="table">
            <thead>
              <tr>
                <!-- <th class="text-left">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    v-model="selectAll"
                    @change="selectAllApp()"
                  />
                </th> -->
                <th>S.No</th>
                <th class="text-left">Company name</th>
                <th>Applicant Name</th>
                <th>Service Name</th>
                <th>Location name</th>
                <th>Service Status</th>
                <th>Payment Status</th>
                <th>Invoice No.</th>
                <th>Scheduled Date</th>
                <th class="text-right">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in reports" :key="index">
                <!-- <td class="text-left">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    v-model="item.checked"
                    @change="check_app"
                  />
                </td> -->
                <td>
                 {{ ++index }}
                </td>
                <td>
                  {{ item.company_name }}
                </td>
                <td>{{ item.applicant }}</td>
                <td>{{ item.service }}</td>
                <td>
                  {{ item.client }}
                </td>
                <td>{{ item.service_status }}</td>
                <td>{{ item.payment_status }}</td>
                <td>
                  {{
                    item.company_invoice_no != ""
                      ? item.company_invoice_no
                      : "-"
                  }}
                </td>

                <td v-if="item.enroll_date && item.enroll_date != ''">
                  {{ dateFormat(item.enroll_date) }}
                </td>
                <td v-else-if="item.schedule_date == null">N/A</td>
                <td v-else-if="item.schedule_date != ''">
                  {{ dateFormat(item.schedule_date) }}
                </td>

                <td class="text-right" v-if="item.amount">
                  $ {{ item.amount | toFixedTwoDigit }}
                </td>
                <td class="text-right" v-else>$ 0.00</td>
              </tr>
              <tr>
                <td :colspan="9">
                  <b>Grand Total</b>
                </td>
                <td class="text-right" style="width: 10%">
                  <b>
                    $
                    {{ grand_total | toFixedTwoDigit }}</b
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" v-if="reports.length < 1 && !getLoadingData && !load">
        <div class="col-md-12 text-center">
          <h4>no report(s) found</h4>
        </div>
      </div>
      <div class="row" v-if="load" style="opacity: 0.8">
        <div class="col-md-12 text-center">
          <h4>Please select the criteria</h4>
        </div>
      </div>

      <div class="row move-below" v-if="getLoadingData">
        <div class="col-md-12 text-center">
          <v-data-loader></v-data-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  FETCH_CLIENTS,
  FETCH_SERVICES,
  FETCH_COMPANY_WISE_REPORT,
  FETCH_MASTER_COMPANIES
} from "@/core/services/store/actions.type";
import {
  SET_BREADCRUMB,
  SET_COMPANY_INVOICE_APPOINTMENTS
} from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
import AdvSearch from "@/includes/search/AdvSearch";
// import * as PDF417 from "pdf417-generator";
export default {
  components: {
    vDataLoader,
    AdvSearch
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "getClients",
      "getServices",
      "getMasterCompanies",
      "getSearchDate"
    ]),
    grand_total: function() {
      let total = 0;
      if (this.reports.length != 0) {
        this.reports.forEach(element => {
          total += element.amount;
        });
      }
      return total;
    }
  },
  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    }
  },
  data() {
    return {
      selectAll: false,
      company: "",
      company_name: "",
      search: {
        service: "all",
        company: "all",
        date_from: "",
        date_to: "",
        service_status: "all",
        payment_status: "all"
      },
      titleworksheet: "",
      json_fields: {
        Company: "company_name",
        "Applicant Name": "applicant",
        Service: "service",
        Location: "client",
        "Service Status": "service_status",
        "Payment Status": "payment_status",
        "Scheduled Date": {
          callback: value => {
            if (value == "") {
              return `${value.schedule_date}`;
            } else {
              return `${moment(value.enroll_date).format("MM/DD/YYYY")}`;
            }
          }
        },
        Amount: "amount"
      },

      getLoadingData: false,
      columns: [
      {
          name: "S.No",
          width: "",
          label: "S.No",
          class: ""
        },
        {
          name: "Company name",
          width: "",
          label: "Company ",
          class: ""
        },
        {
          name: "Applicant Name",
          width: "",
          label: "Applicant Name",
          class: ""
        },
        {
          name: "Service Name",
          width: "",
          label: "Service ",
          class: ""
        },
        {
          name: "Location name",
          width: "",
          label: "Location ",
          class: ""
        },
        {
          name: "location Name",
          width: "",
          label: "Location Name",
          class: ""
        },
        {
          name: "Service Status",
          width: "",
          label: "Service Status",
          class: ""
        },

        {
          name: "Payment Status",
          width: "",
          label: "Payment Status",
          class: "text-center"
        },
        {
          name: "Invoice No",
          width: "",
          label: "Invoice No",
          class: ""
        },
        {
          name: "Scheduled Date",
          width: "",
          label: "Scheduled Date",
          class: ""
        },
        {
          name: "amount",
          width: "",
          label: "Amount",
          class: "text-right"
        }
      ],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Report",
          route: ""
        },
        {
          id: 2,
          title: "Company Wise Fingerprint Service Report",
          route: ""
        }
      ],
      reports: [],
      header: [],
      footer: [],
      load: true,
      excelreports: []
    };
  },

  mounted() {
    this.$store.dispatch(FETCH_CLIENTS);
    this.$store.commit(SET_COMPANY_INVOICE_APPOINTMENTS, []);
    let serviceOutlet = this.currentUser.u.is_client ? "self" : "all";
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.$store.dispatch(FETCH_SERVICES, serviceOutlet);
    this.$store.dispatch(FETCH_MASTER_COMPANIES);
  },

  methods: {
    advSearch(value) {
      this.selectAll = false;
      this.getLoadingData = true;
      this.load = false;
      let { header, search, footer } = value;
      this.header = header;
      this.titleworksheet = search.date_from + " - " + search.date_to;
      // this.footer = footer;
      this.search = { ...search };
      this.tablereset();
      this.$store
        .dispatch(FETCH_COMPANY_WISE_REPORT, search)
        .then(res => {
          this.reports = [];
          this.getLoadingData = false;
          res.data.map(data => {
            this.reports.push({
              id: data.id,
              abbreviation_code: data.abbreviation_code,
              checked: false,
              company_name: data.company_name,
              schedule_date: data.schedule_date,
              service: data.service,
              client: data.client,
              applicant: data.applicant,
              fullname: data.fullname,
              service_status: data.service_status,
              payment_status: data.payment_status,
              company_invoice_no: data.company_invoice_no,
              amount: data.amount,
              client_display_name: data.client_display_name,
              company_invoiced: data.company_invoiced
            });
          });
          // this.reports = res.data;
          this.excelreports = [...res.data];
          let data = [...res.data];
          let total = 0;
          if (data.length != 0) {
            data.forEach(element => {
              total += element.amount;
            });
            let gtotal = {
              company_name: "",
              schedule_date: "Grand Total",
              service: "",
              client: "",
              applicant: "",
              service_status: "",
              payment_status: "",
              amount: total
            };
            this.excelreports.push(gtotal);
          }
          let foo = {
            company_name: "",
            schedule_date: footer[0],
            service: "",
            client: "",
            applicant: "",
            service_status: "",
            payment_status: "",
            amount: footer[1]
          };
          this.excelreports.push(foo);
        })
        .catch(err => {
          this.getLoadingData = false;
          this.msg = err.error;
        });
    },
    tablereset() {
      if (this.search.company != "all") {
        this.columns = [
        {
          name: "S.No",
          width: "",
          label: "S.No",
          class: ""
        },
          {
            name: "Applicant Name",
            width: "",
            label: "Applicant Name",
            class: ""
          },
          {
            name: "Service Name",
            width: "",
            label: "Service ",
            class: ""
          },
          {
            name: "Location name",
            width: "",
            label: "Location ",
            class: ""
          },
          {
            name: "Service Status",
            width: "",
            label: "Service Status",
            class: ""
          },

          {
            name: "Payment Status",
            width: "",
            label: "Payment Status",
            class: "text-center"
          },
          {
            name: "Invoice No",
            width: "",
            label: "Invoice No",
            class: ""
          },
          {
            name: "Scheduled Date",
            width: "",
            label: "Scheduled Date",
            class: ""
          },
          {
            name: "amount",
            width: "",
            label: "Amount",
            class: "text-left"
          }
        ];
        this.json_fields = {
          "Applicant Name": "applicant",
          Service: "service",
          Location: "client",
          "Service Status": "service_status",
          "Payment Status": "payment_status",
          "Scheduled Date": "schedule_date",
          Amount: "amount"
        };
      } else {
        this.json_fields = {
          Company: "company_name",
          "Applicant Name": "applicant",
          Service: "service",
          Location: "client",
          "Service Status": "service_status",
          "Payment Status": "payment_status",
          "Scheduled Date": "schedule_date",
          Amount: "amount"
        };
        this.columns = [
          {
            name: "Company name",
            width: "",
            label: "Company ",
            class: ""
          },
          {
            name: "Applicant Name",
            width: "",
            label: "Applicant Name",
            class: ""
          },
          {
            name: "Service Name",
            width: "",
            label: "Service ",
            class: ""
          },
          {
            name: "Location name",
            width: "",
            label: "Location ",
            class: ""
          },
          {
            name: "Service Status",
            width: "",
            label: "Service Status",
            class: ""
          },

          {
            name: "Payment Status",
            width: "",
            label: "Payment Status",
            class: "text-center"
          },
          {
            name: "Invoice No",
            width: "",
            label: "Invoice No",
            class: ""
          },
          {
            name: "Scheduled Date",
            width: "",
            label: "Scheduled Date",
            class: ""
          },
          {
            name: "amount",
            width: "",
            label: "Amount",
            class: "text-left"
          }
        ];
      }
    },
    refreshData() {
      this.pagination = {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      };
    },
    selectAllApp() {
      if (this.selectAll) {
        this.show_invoice = true;
        this.reports.filter(app => {
          return (app.checked = true);
        });
      } else {
        this.show_invoice = false;
        this.reports.filter(app => {
          return (app.checked = false);
        });
      }
    },
    check_app() {
      var newarray = this.reports.filter(chart => chart.checked === true);
      if (newarray.length > 0) {
        this.show_invoice = true;
      } else {
        this.selectAll = false;
        this.show_invoice = false;
      }
    },
    createInvoice() {
      this.$store.commit(SET_COMPANY_INVOICE_APPOINTMENTS, []);
      if (this.search.company == "all") {
        this.$toastr.e("Please select a company");
      } else {
        var data_array = [];
        var newarray = this.reports.filter(chart => chart.checked === true);
        newarray.map(data => {
          data_array.push(data.id);
        });
        if (data_array.length > 0) {
          window.localStorage.setItem(
            "company_invoice_appointment",
            data_array
          );
          this.$store.commit(SET_COMPANY_INVOICE_APPOINTMENTS, data_array);
          this.company = this.search.company;
          this.$router.push({
            name: "company.report.invoicedetail",
            params: {
              slug: this.$route.params.client_slug,
              company_id: this.search.company
            }
          });
        } else {
          this.$toastr.e(
            "Please select aleast one appointments to generate the report"
          );
        }
      }
    },
    statusColor(status) {
      if (status == "P") {
        return "warning";
      }
      if (status == "C") {
        return "success";
      }
      if (status == "U") {
        return "info";
      }
      if (status == "Cancel") {
        return "danger";
      }
    },
    selectedPage(n) {
      this.pagination.current_page = n;
      //   this.appData();
    },
    nextPage() {
      this.pagination.current_page = this.pagination.current_page + 1;
      //   this.appData();
    },
    lastPage() {
      this.pagination.current_page = this.pagination.total_page;
      //   this.appData();
    },
    firstPage() {
      this.pagination.current_page = 1;
      //   this.appData();
    },
    previousPage() {
      this.pagination.current_page = this.pagination.current_page - 1;
      //   this.appData();
    }
  }
};
</script>
